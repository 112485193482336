import React, { Component } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { ContactModal } from "../Modal";

//CSS Imports
import "../../css/Navbar.css";

class NavigationBar extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  closeModal = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          sticky="top"
        >
          <Navbar.Brand href="#home" id="nav-brand">
            AMIR <i className="fab fa-battle-net" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav>
              <Nav.Link href="#about" className="nav-items">
                About Me
              </Nav.Link>
              <Nav.Link href="#careerPath" className="nav-items">
                Career Path
              </Nav.Link>
              <ContactModal
                props={this.state.show}
                closeModal={this.closeModal}
              />
              <Button
                className="nav-items"
                id="getInTouch"
                onClick={this.showModal}
              >
                Get In Touch
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

export default NavigationBar;
