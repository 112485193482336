import React from "react";
import AutoPlayVideo from "../Components/Video";
import HeroImage from "../Components/HeroImage";

// CSS Imports
import "../App.css";
import "../css/HeroSection.css";

function HeroSection() {
  return window.innerWidth > 1448 ? (
    <AutoPlayVideo src="/videos/video-2.mp4" />
  ) : (
    <HeroImage />
  );
}

export default HeroSection;

{
  /*     <div className="hero-btns">
                <Button className="btns" buttonStyle="btn--outline" buttonSize="btn--large">GET STARTED</Button>
                <Button className="btns" buttonStyle="btn--outline" buttonSize="btn--large">WATCH TRAILER <i className="far fa-play-circle"></i></Button>
            </div>
    */
}
