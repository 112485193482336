import React from "react";
import { Navbar } from "react-bootstrap";

import "../css/Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Navbar.Brand className="social-logo" href="#home">
              AMIR
              <i className="fab fa-battle-net" />
            </Navbar.Brand>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
