let timeLineElements = [
  {
    id: 1,
    title: "Software Developer/Quality Assurance Automation Analyst",
    company: "Morgan Stanley",
    location: "Calgary, AB, Canada",
    description:
      "Currently working, full-time, as a QA Automation Analyst. The position is revolved around creating automated tests, with an equal amount of manual testing, using Java Selenium for new features for the Morgan Stanley Wealth Management platform. I've also begun taking on development responsibilities alongside of my current ones in an effort to keep my coding skills sharp and up-to-date",
    date: "September 2019 - Present",
    icon: "work",
  },
  {
    id: 2,
    title: "Co-Founder/Front-End Developer",
    company: "Liveworks",
    location: "Calgary, AB, Canada",
    description:
      "I, along with 3 of my colleagues, have Co-Founded a company called Liveworks that aims to provide a service for businesses in the Hospitality industry to manage and schedule their employees. We ultimately decided to no longer pursue this venture because the rate at which our competitors were producing features was at a pace that we couldn't keep up with",
    date: "July 2021 - December 2021",
    icon: "work",
  },
  {
    id: 3,
    title: "Software Developer",
    company: "Stream Data Systems",
    location: "Calgary, AB, Canada",
    description:
      "My first job coming out of University. I was responsible for so many things at this company, from dealing with clients to rebuilding legacy software and managing system infrastructure. I will always be grateful to Stream for the knowledge and experience they've given me",
    date: "October 2018 - August 2019",
    icon: "work",
  },
  {
    id: 4,
    title: "Graduation",
    company: "University of Calgary",
    location: "Calgary, AB, Canada",
    description: "Finished my degree!!!",
    date: "November 2018",
    icon: "school",
  },
  {
    id: 5,
    title: "codeTeen Mentor",
    company: "Calgary Public Library",
    location: "Calgary, AB, Canada",
    description:
      "Helping teens aged thirteen to seventeen learn coding skills as they work through tutorials (as beginners) or their own projects using HTML, CSS, and JavaScript",
    date: "June 2018 - Present",
    icon: "work",
  },
  {
    id: 6,
    title: "Software Developer",
    company: "Nexbit",
    location: "Calgary, AB, Canada",
    description:
      "I was contracted by Nexbit to take on their venture which aggragates venues and displays the most lively locations around the user, in the city they're located in and around the globe!",
    date: "September 2017 - February 2018",
    icon: "work",
  },
  {
    id: 7,
    title: "Start University",
    company: "University of Calgary",
    location: "Calgary, AB, Canada",
    description:
      "The next chapter of my life begins. I'm in university pursuing a Bachelors of Science in Computer Science degree, specializing in Software Engineering",
    date: "September 2014",
    icon: "school",
  },
];

export default timeLineElements;
