import React from "react";
import { Button, Modal } from "react-bootstrap";
import ContactForm from "./Contact";

const ContactModal = ({ closeModal, props }) => {
  return (
    <div>
      <Modal show={props}>
        <Modal.Body>
          <ContactForm />
        </Modal.Body>
        <Button onClick={closeModal}>Cancel</Button>
      </Modal>
    </div>
  );
};

export { ContactModal };
