import React from "react";
import emailjs from "emailjs-com";
import { Form, Button } from "react-bootstrap";

// CSS Imports
import "../css/Contact.css";
import "bootstrap/dist/css/bootstrap.min.css";

function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "gmail",
      "template_iczutnp",
      e.target,
      "user_HMBnCchKqT1W8BTqW40um"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  e.target.reset();
}

function ContactForm() {
  return (
    <div id="contact">
      <h1>Get In Touch</h1>
      <Form>
        <Form.Group controlId="formBasicEmail" onSubmit={sendEmail}>
          <Form.Control
            type="email"
            placeholder="Enter Name"
            name="user_name"
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="Enter Email"
            name="user_email"
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            as="textarea"
            placeholder="Message"
            rows={3}
            name="message"
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default ContactForm;
