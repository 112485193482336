import React from 'react'
import { ReactComponent as WorkIcon } from '../images/work.svg'
import { ReactComponent as SchoolIcon } from '../images/school.svg'
import timeLineElements from '../Data/timeLineElements'
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component"

import "react-vertical-timeline-component/style.min.css"
import "../css/Portfolio.css"


function Portfolio() {

    let workIconStyles = { background : "#06D6A0" };
    let schoolIconStyles = { background : "#F9C74F" };

    return(
        <>
        <div className="portfolio" id='careerPath'>
            <h1 className="title">My Career Path</h1>

            <VerticalTimeline>
                {timeLineElements.map((element) => {

                    let isWorkIcon = element.icon === "work";

                    return(
                        <VerticalTimelineElement 
                        key = {element.key}
                        date = {element.date}
                        dateClassName = "date"
                        iconStyle = { isWorkIcon ? workIconStyles : schoolIconStyles }
                        icon = { isWorkIcon ? <WorkIcon /> : <SchoolIcon /> }
                        >
                        <h3 className="vertical-timeline-element-title">{element.title}</h3>
                        <h5 className="vertical-timeline-element-subtitle">{element.company}</h5>
                        <p id="description">{element.description}</p>
                        </VerticalTimelineElement>
                    );
                })}
            </VerticalTimeline>
        </div>
        </>
    )
}

export default Portfolio