import React from "react";
import { Button, Image } from "react-bootstrap";

// CSS Imports
import "../css/AboutMe.css";
import "../css/Button.css";

//Media Import
import pdf from "../Documents/V2 - Amir Resume (Software Development).pdf";
import aboutMeImage from "../images/aboutMe.jpg";

function onResumeClick() {
  window.open(pdf);
}

function linkedIn() {
  window.open("https://linkedin.com/in/amir-siddavatam");
}

function AboutMe() {
  return (
    <div id="about" className="about-me">
      <div className="container-fluid">
        <div className="column">
          <div className="row-lg-2">
            <h1>ABOUT ME</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 no-padding">
            <div className="about-img">
              <Image src={aboutMeImage} id="aboutMeImage" rounded></Image>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-12">
                <h2>Amir Siddavatam</h2>
                <h4>
                  Software Engineer/QA Automation Analyst | Morgan Stanley
                </h4>
                <div className="about-title">
                  <p>
                    <br />
                    Hey there! Thanks for coming accross my portfolio site. I'm
                    going to be keeping this website up-to-date with the most
                    current information in regards to my professional life.{" "}
                    <br /> <br />A little bit about me, I'm a Computer Science
                    grad who is fond of technology and anything related to it.
                    As passionate about tech as I am, I love being outdoors and
                    enjoying the activities that come with it such as
                    Rollerblading, Mountain Biking, Snowboarding, Ice skating,
                    you name it. Another thing to know about me is that I'm an
                    individual who is committed and dependable. You give me a
                    task and a deadline, one way or another I will get it done.
                    If you're interested in learning more, check out my resume
                    or give me a shout!
                  </p>
                </div>
              </div>
              <div className="hero-btns">
                <Button className="resume-btn" onClick={onResumeClick}>
                  Check out my Resume
                </Button>
                <Button className="resume-btn" onClick={linkedIn}>
                  My LinkedIn
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
