import React from "react";

var AutoPlayVideo = function (props) {
  return (
    <div className="hero-container" id="home">
      <video loop muted autoPlay playsInline src={props.src} />
      <h1>AMIR SIDDAVATAM</h1>
    </div>
  );
};

export default AutoPlayVideo;
