import React from "react";

var HeroImage = function () {
  return (
    <div className="hero-container-image" id="home">
      <h1>AMIR SIDDAVATAM</h1>
    </div>
  );
};

export default HeroImage;
